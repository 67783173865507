import { useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import "./ArquivoTecnico.css";
import FormPEC from "./PEC";
import FormOSE from "./OSE";
import { AecFolderContext } from "../../../Provider/AecFolderProvider";
import CustomTabPanel from "../CustomTabPanel";

export default function FormArquivoTecnico() {
    const { selectedTab, a11yProps, handleChangeTab } =
        useContext(AecFolderContext);
    const isMobile = useMediaQuery("(min-width: 45em)"); // Defina aqui o tamanho para considerar como mobile
    const tabs = [
        {
            label: isMobile ? "Projetos, Estudos e Consultoria" : "PEC",
            index: 0,
        },
        {
            label: isMobile
                ? "Obras, Serviços de Engenharia e Equipamentos"
                : "OSE",
            index: 1,
        },
    ];
    return (
        <Box sx={{ width: "100%", display: "grid", flexDirection: "column" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{ textTransform: "none" }}
                >
                    <Tab
                        label={tabs[0].label}
                        {...a11yProps(0)}
                        sx={{ textTransform: "none" }}
                    />
                    <Tab
                        label={tabs[1].label}
                        {...a11yProps(1)}
                        sx={{ textTransform: "none" }}
                    />
                </Tabs>
            </Box>
            <CustomTabPanel
                className="custom-tab1"
                value={selectedTab}
                index={0}
            >
                <FormPEC />
            </CustomTabPanel>

            <CustomTabPanel
                className="custom-tab1"
                value={selectedTab}
                index={1}
            >
                <FormOSE />
            </CustomTabPanel>
        </Box>
    );
}
