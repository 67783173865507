import { AppBar } from "@mui/material";
import { VisualBimLogo } from "./VisualBimLogo";
import { SabespLogo } from "./SabespLogo";
import { BimSabespLogo } from "./BimSabespLogo";

import "./Header.css";
import { AccountButton } from "./Account";
import AppAlert from "../AppAlert";

const alertStyle = {
    margin: "24px 48px",
};

const Header = () => {
    const alertText =
        "Alerta de manutenção no período de 16h00 às 18h00 (BRT) de 25 de abril.";
    const alertType = "warning";
    var today = new Date();

    const limitHour = new Date('2024-04-25T18:00:00.000');

    return (
        <div>
            <AppBar position="relative" className="header">
                <div className="logos-container">
                    <div>
                        <VisualBimLogo />                    
                    </div>

                    <div className="sabesp-logos">
                        <SabespLogo />
                        <BimSabespLogo />
                        <AccountButton />
                    </div>
                </div>
            </AppBar>
            {today < limitHour ? (
                <div style={alertStyle}>
                    <AppAlert text={alertText} type={alertType}></AppAlert>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Header;
