import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./Home";
import Agua from "./Agua";
import Esgoto from "./Esgoto";
import ArquivoTecnico from "./ArquivoTecnico";
import Padronizacoes from "./Padronizacoes";
import Signos from "./Signos";
import Administracao from "./Administracao";
import Form from "../modules/sat-migration/components/templates/Form"; //Módulo migração de metadados

const Router = () => {
   return(
       <Routes>
           <Route element = { <Home /> }  path="/" exact />
           <Route element = { <Administracao /> }  path="administracao" />
           <Route element = { <Agua /> }  path="agua" />
           <Route element = { <Esgoto /> }  path="esgoto" />
           <Route element = { <ArquivoTecnico /> }  path="arquivoTecnico" />
           <Route element = { <Padronizacoes /> }  path="tecbim" />
           <Route element = { <Signos /> }  path="signos" />
           <Route element = { <Form /> }  path="migracao" />
       </Routes>
   )
}

export default Router;