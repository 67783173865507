import Alert from '@mui/material/Alert';

const AppAlert = ({text, type}) => {
    return (
        <Alert variant="filled" severity={type}>
            {text}
        </Alert>
    );
}

export default AppAlert;