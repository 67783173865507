import { api } from './configs/axiosConfig'
import { defineCancelApiObject } from './configs/axiosUtils'

/**
 * API functions for autodesk-hub-related operations.
 * @namespace hubsApi
 */
export const hubsApi = {

  /**
   * Fetches a list of hubs from the Autodesk Api.
   * @async
   * @param {boolean} [cancel=false] - Flag indicating whether to enable request cancellation.
   */
  getHubs: async function (cancel = false) {
    const token = localStorage.getItem("access_token");

    const response = await api.request({
      url: `${process.env.REACT_APP_API_BASE_URL}project/v1/hubs`,
      method: "GET",
      headers: {Authorization: `Bearer ${token}`},
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })

    return response.data.data.map((hub) => {
      //Generating an array of objects with name and id.
      return {
        label: hub.attributes.name,
        value: hub.id
      }
    });
  },

  /**
   * Fetches a list of projects by hub ID from the Autodesk Api.
   * @async
   * @param {string} [hubId] - The ID of the hub for which to fetch projects.
   * @param {boolean} [cancel=false] - Flag indicating whether to enable request cancellation.
   */
  getProjectsByHub: async function (hubId, cancel = false) {
    const token = localStorage.getItem("access_token");
    if(!hubId) return;

    const response = await api.request({
      url: `${process.env.REACT_APP_API_BASE_URL}project/v1/hubs/${hubId}/projects`,
      method: "GET",
      headers: {Authorization: `Bearer ${token}`},
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })

    return response.data.data.map((project) => {
      //Generating an array of objects with name and id.
      return {
        label: project.attributes.name,
        value: project.id
      }
    });
  }
}

const cancelApiObject = defineCancelApiObject(hubsApi) // Object for managing request cancellation for each API endpoint in hubsApi
