import LogoutIcon from "@mui/icons-material/Logout";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Divider from "@mui/material/Divider";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { Avatar, Menu, MenuItem, createTheme, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Provider/AuthProvider";
import { ColorModeContext } from "../../Provider/ColorModeProvider";

export const AccountButton = () => {
    const {
        userData,
        isAdministrationScreen,
        setIsAdministrationScreen,
        stringAvatar,
    } = useContext(AecFolderContext);
    
    const { hasToken } = useContext(AuthContext); // Destructuring hasToken from AuthContext
    const { toggleColorMode } = useContext(ColorModeContext);
    const [anchorEl, setAnchorEl] = useState(null); // State variable anchorEl to manage the anchor element of the menu
    const [menuKey, setMenuKey] = useState(Math.random()); // State variable menuKey to force menu re-rendering when needed

    let navigate = useNavigate();    
    const theme = useTheme();

    let currentHost = window.location.protocol + "//" + window.location.host;

    const handleLogoff = () => { // Function to handle user logoff
        handleClose(); // Close the menu
        localStorage.clear(); // Clear localStorage
        let url = `${process.env.REACT_APP_API_BASE_URL}/authentication/v2/logout?post_logout_redirect_uri=${currentHost}`; // Building Logout URL from Autodesk Logout
        window.location.href = url; // Redirecting the user to the logout URL
    };

    const handleAdminArea = () => {
        handleClose();
        let path = `/administracao`;
        navigate(path);
    };

    const handleHome = () => {
        handleClose();
        let path = `/`;
        navigate(path);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => { // useEffect to check and set the administration screen status
        let screen = window.location.href.split("/"); // Get the current URL and split it
        screen = screen[screen.length - 1]; // Get the last part of the URL
        setIsAdministrationScreen(screen.toLowerCase() === "administracao"); // Check if the last part is "administracao" and set the administration screen status
    }, []);

    useEffect(() => { // useEffect to update menu key when token status changes
        setMenuKey(Math.random()); // Update the menu key to force re-rendering of the menu
    }, [hasToken]);



    return (
        <>
            {userData.image?.includes("default") ? (
                <Avatar
                    {...stringAvatar(userData.fullName)}
                    alt="Foto perfil"
                    onClick={handleClick}
                    style={{ cursor: "pointer" }}
                />// conditional rendering of the user's image, if they have a photo in their autodesk account, render the initials of the name, if not, render the photo
            ) : (
                <Avatar
                    src={userData.image}
                    key={userData.image}
                    alt="Foto perfil"
                    onClick={handleClick}
                    style={{ cursor: "pointer" }}
                />
            )}

            {theme.palette.mode === "dark" ? (
                <KeyboardArrowDownIcon
                onClick={handleClick}
                style={{ cursor: "pointer", color: 'white' }}
            />
            ):(
                <KeyboardArrowDownIcon
                onClick={handleClick}
                style={{ cursor: "pointer", color: "#000" }}
            />
            )}
            
            <Menu
                style={{ top: "12px" }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                key={menuKey}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                    gap: "1px",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                    gap: "1px",
                }}
            >
                <MenuItem key="name" className="user-info user-name" disabled>
                    <strong>{userData.fullName}</strong>
                </MenuItem>

                <MenuItem key="email" className="user-info user-email" disabled>
                    {userData.email}
                </MenuItem>

                <Divider key="divider2" variant="middle" />

                {userData.company === "FF Solutions" && userData.access === "account_admin" ? 
                (
                    isAdministrationScreen ? 
                    (
                        <MenuItem key="home" onClick={handleHome}>
                            <HomeOutlinedIcon style={{ marginRight: "8px" }} />
                            Menu
                        </MenuItem>
                    ) : (
                        <MenuItem key="adminArea" onClick={handleAdminArea}>
                            <AdminIcon style={{ marginRight: "8px" }} />
                            Área do Administrador
                        </MenuItem>
                    )
                ) : null}
                <MenuItem key="darkMode" onClick={toggleColorMode}> 
                    {theme.palette.mode === 'dark' ? <Brightness7Icon style={{ marginRight: "8px" }} /> : <Brightness4Icon style={{ marginRight: "8px" }} />}
                    {theme.palette.mode === 'dark' ? <>Modo Claro</> : <>Modo Escuro</>}
                </MenuItem>
                <MenuItem key="logoff" onClick={handleLogoff}>
                    <LogoutIcon style={{ marginRight: "8px" }} />
                    Logoff
                </MenuItem>

            </Menu>
        </>
    );
};
