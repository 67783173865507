import { React, useState, useEffect, useRef } from 'react';
import styles from './index.module.css';

import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import Alert from '@mui/material/Alert';
import useFileMigration from '../../../hooks/useFileMigration';
import { hubsApi } from '../../../apis/hubsApi';
import { projectsApi } from '../../../apis/projectsApi';

/**
 * Form element component with file input, migration button, and response alert.
 * The form itself where the user uploads the file and submits the request to the backend.
 */
const FormElement = () => {
  const { response, file, btnDisabled, selectFile, migrateFile } = useFileMigration();
  const [showAlert, setShowAlert] = useState(false);
  const [selectedHub, setSelectedHub] = useState("");
  const [hubs, setHubs] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [projects, setProjects] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {if (response) setShowAlert(true)}, [response]);

  useEffect(() => {
    (async () => setHubs(await hubsApi.getHubs()))();
  }, []);

  useEffect(() => {
    const allowedRoles = process.env.REACT_APP_ALLOWED_ROLES_FOR_MIGRATION || [];

    const fetchProjects = async () => {
      if (selectedHub) {
        const projects = await hubsApi.getProjectsByHub(selectedHub);

        const filteredRoles = await Promise.all(
          projects.map(async project => {
            const userRolesByProject = await projectsApi.getUserRolesByProject(project.value);

            if (!userRolesByProject || userRolesByProject.length === 0) return null;
    
            return {
              projectId: project.value,
              roles: userRolesByProject,
            };
          })
        ).then(userRoles => userRoles?.filter(role => !!role));

        const filteredProjects = projects.filter(project => {
          const userRole = filteredRoles.find(ur => ur.projectId === project.value);
          if (!userRole) return false;
          const hasRole = userRole.roles.some(role => allowedRoles.includes(role.name));
          return hasRole;
        });
       
        setProjects(filteredProjects);
      } 
      
      else setProjects([]);
    };

    fetchProjects();
  }, [selectedHub]);

  return (
    <div className={styles.formElement}>
      <div className={styles.projectSelect}>
        <Select
          labelId="hubSelectLabel"
          id="hubSelect"
          value={selectedHub}
          labelText="Escolha um Hub"
          onChange={(e) => setSelectedHub(e.target.value)}
          options= {hubs}
          disabled={selectedProject !== ""}
        />

        {selectedHub && (<Select
          labelId="projectSelectLabel"
          id="projectSelect"
          value={selectedProject}
          labelText="Escolha um Projeto"
          onChange={(e) => setSelectedProject(e.target.value)}
          options= {projects}
          disabled={!selectedHub}
        />)}
      </div>

      {selectedProject && (
        <>
          <Input placeholder={file == null ? "Selecione um arquivo de metadados" : file.name} accept=".xlsx" disabled={file == null ? false : true} id="formInput" onFileSelect={(inputFile) => selectFile(inputFile)} inputRef={fileInputRef}/>
          <Button content="Iniciar" onClick={() => migrateFile(fileInputRef, selectedProject)} disabled={btnDisabled} id="formButton"/>
        </>
      )}

      {showAlert && response && (
        <Alert severity={response.status === 200 ? "success" : "error"} onClose={() => setShowAlert(false)}>{response.message}</Alert>
      )}
    </div>
  )
}

export default FormElement;