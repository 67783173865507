import React from 'react';

import MuiButton from '@mui/material/Button';

/**
 * Generic button component.
 * @param {string} content - The text content of the button.
 * @param {function} onClick - The function to be called when the button is clicked.
 * @param {boolean} [disabled=false] - Whether the button is disabled or not.
 * @param {string} [id] - The id attribute of the button.
 */
const Button = ({content, onClick, disabled, id}) => {
  return (
    <MuiButton variant="contained" onClick={onClick} disabled={disabled} id={id}>
      {content}
    </MuiButton>
  )
}
export default Button;