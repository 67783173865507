import { Card } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../containers/footer";
import { useContext, useEffect } from "react";
import { ProjectContext } from "../../Provider/ProjectProvider";
import FormSidebar from "../../components/Form/Sidebar";
import FormArquivoTecnico from "../../components/Form/ArquivoTecnico";

import ItemSearch from "../../components/ItemSearch";
import { AecFolderContext } from "../../Provider/AecFolderProvider";

const cardContainer = {
    borderRadius: "8px",
    padding: "24px",
    margin: "24px 48px",
    display: "grid",
};

const Arquivo = () => {
    const { folderContents } = useContext(AecFolderContext);
    const { changeSelectedProject, Projects } = useContext(ProjectContext);

    useEffect(() => {
        changeSelectedProject(Projects.ARQUIVO);
    });

    return (
        <>
            <Header />
            <main>
                <Card style={cardContainer}>
                    <FormSidebar />
                    <FormArquivoTecnico />
                    {folderContents.length ? <ItemSearch /> : <></>}
                </Card>
            </main>
            <Footer />
        </>
    );
};

export default Arquivo;
