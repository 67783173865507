import { Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const formButtonMenuStyle = {
    padding: "0.5rem 0",
    zIndex: 2,
    textAlign: "center",
    borderRadius: 8,
    transform: "rotate(-90deg)",
    marginTop: 24,
    width: 120,
};

const imageStyle = {
    width: 30,
    transform: "rotate(90deg)",
};

const FormButtonMenu = ({
    onClick,
    text,
    color,
    width,
    padding,
    image,
    isSelected,
}) => {
    return (
        <Tooltip title={text}>
            <Button
                onClick={onClick}
                variant="outlined"
                style={{
                    ...formButtonMenuStyle,
                    ...{
                        color: color,
                        borderColor: color,
                        textTransform: "none",
                        width: width,
                    },
                    ...(isSelected
                        ? { backgroundColor: color, color: "white" }
                        : {}),
                }}
            >
                <img style={imageStyle} src={image} alt={text} />
                {/* {text} */}
            </Button>
        </Tooltip>
    );
};

export default FormButtonMenu;
