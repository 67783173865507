import { createContext, useContext } from "react";
import {
    fetchToken,
    redirectToAuth,
    RefreshToken,
} from "../../service/AuthService";
import { AecFolderContext } from "../AecFolderProvider";

export const AuthContext = createContext();

/**
 * Authentication provider that encapsulates authentication logic and provides authentication context to child components.
 * @param {object} children Children Components  .
 * @returns Authentication Provider component.
 */
export function AuthProvider({ children }) {
    const { getUsersPermissions, LogTrigger, projectsPermissions, getUser } =
        useContext(AecFolderContext);
    let hasToken = false

    /**
     * Function to check if the authentication token is present and valid.
     */
    async function verifyToken() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = localStorage.getItem("access_token");
        hasToken = !!token;

        const code = urlParams.get("code");
        const has_token_expired = is_token_expired();

        if (has_token_expired === true) { 
            await refresh_token(localStorage.getItem("refresh_token"));
        } else if (!code && !hasToken) {
            localStorage.clear();
            login();
        } else if (code && !hasToken) {
            await getToken(code);
        } else if (projectsPermissions.length === 0) {
            getUsersPermissions();
            await getUser();
        }
    }

    async function login() {
        try {
            await redirectToAuth();
        } catch (ex) {}
    }

     /**
     * Função para obter o token de acesso.
     * @param {string} code Código de autorização retornado após a autenticação.
     */
    async function getToken(code) {
        await fetchToken(code)
            .then((res) => {
                if (res.status === 200) {
                    const currentDate = Math.floor(new Date().getTime() / 1000);
                    localStorage.setItem("access_token", res.data.access_token);
                    localStorage.setItem(
                        "expires",
                        currentDate + res.data.expires_in
                    );
                    localStorage.setItem(
                        "refresh_token",
                        res.data.refresh_token
                    );

                    getUsersPermissions();
                    LogTrigger("LOGIN", "", "", "-");
                }
            })//if code expired, ask for permissions again
            .catch((res) => {
                //if code expired, ask for permissions again
                if (
                    res.response?.data.errorCode === "AUTH-004" ||
                    res.response?.status === 400
                ) { 
                    login();
                }
            });
    }
     /**
     * Function to update the access token.
     * @param {string} refresh_token Refresh token used to obtain a new access token.
     */
    async function refresh_token(refresh_token) {
        await RefreshToken(refresh_token)
            .then((res) => {
                if (res.status === 200) {
                    const currentDate = Math.floor(new Date().getTime() / 1000);
                    localStorage.setItem("access_token", res.data.access_token);
                    localStorage.setItem(
                        "expires",
                        currentDate + res.data.expires_in
                    );
                    localStorage.setItem(
                        "refresh_token",
                        res.data.refresh_token
                    );

                    verifyToken();
                }
            })
            .catch((res) => {
                //if code expired, ask for permissions again
                if (
                    res.response?.data.errorCode === "AUTH-004" ||
                    res.response?.status === 400
                ) {
                    localStorage.clear();
                    login();
                }
            });
    }

    /**
     * Function to check if the access token has expired.
     * @returns {boolean} True if the token has expired, false otherwise.
     */
    function is_token_expired() {
        //Obter a hora atual em minutos
        const maxAgeValue = localStorage.getItem("expires");
        const currentDate = Math.floor(new Date().getTime() / 1000);

        return maxAgeValue ? maxAgeValue - currentDate <= 0 : false;
    }
    return (
        <AuthContext.Provider
            value={{
                login,
                verifyToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
