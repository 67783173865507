import { Alert, Card, Snackbar } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../containers/footer";
import { useContext, useEffect } from "react";
import { ProjectContext } from "../../Provider/ProjectProvider";
import FormSidebar from "../../components/Form/Sidebar";
import { AlertContext } from "../../Provider/AlertProvider";


const Padronizacoes = () => {
    const { changeSelectedProject, Projects } = useContext(ProjectContext);

    const { errorMessage, showErrorAlert, displayErrorAlert } =
        useContext(AlertContext);

    const handleClear = () => {
        //clearFolderContents();
    };

    useEffect(() => {
        changeSelectedProject(Projects.TECBIM);
        displayErrorAlert("Em desenvolvimento");
    });

    return (
        <>
            <Header />
            <main>
                {/* <Card style={cardContainer}> */}
                <Card >
                    <FormSidebar />
                    <Snackbar
                        open={showErrorAlert}
                        sx={{ height: "100%" }}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <Alert severity="error">{errorMessage}</Alert>
                    </Snackbar>
                </Card>
            </main>
            <Footer />
        </>
    );
};

export default Padronizacoes;
