import { Card, CardContent, Divider, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import { styled } from "@mui/material/styles";
import "./Metadata.css";

const StyledTextField = styled(TextField)({
    "& .MuiOutlinedInput-input": {
        color: "#737d85",
    },
    "& .MuiInputLabel-root": {
        color: "#737d85",
    },
});

const cardHeaderStyle = {
    display: "flex",
    gap: 10,
    alignItems: "center",
    marginBottom: 24,
};

const iconStyle = {
    fontSize: 22,
    color: "#718797",
};

const titleHeaderStyle = {
    color: "#718797",
    fontSize: 16,
};

export const MetadataAgua = ({ metadataContainerRef }) => {
    const { selectedFile, itemsMetadata } = useContext(AecFolderContext);
    const [fileInfo, setFileInfo] = useState();

    useEffect(() => {
        if (itemsMetadata.length && selectedFile) {
            if (selectedFile) {
                var customAtt = itemsMetadata.filter(
                    (item) => item.folder_item_id === selectedFile.id
                );
                setFileInfo(customAtt);
            } else {
                setFileInfo();
            }
        } else {
            setFileInfo();
        }
    }, [selectedFile, itemsMetadata]);

    function getValue(attName) {
        try {
            if (fileInfo) {
                return fileInfo[0][attName] === "" ||
                    fileInfo[0][attName] === "-" ||
                    fileInfo[0][attName] === undefined ||
                    fileInfo[0][attName] === null
                    ? " "
                    : fileInfo[0][attName];
            } else {
                return " ";
            }
        } catch (ex) {
            return " ";
        }
    }

    return (
        <div ref={metadataContainerRef} className="metadata-info-container">
            <Card className="card-metadata">
                <div style={cardHeaderStyle}>
                    <PlagiarismOutlinedIcon style={iconStyle} />
                    <label style={titleHeaderStyle}>
                        Detalhes do documento
                    </label>
                </div>
                <Divider />
                <CardContent className="card-content-metadata">
                    <div className="card-metadata-subgrid">
                        <StyledTextField
                            label="Descrição"
                            value={getValue("titulo")}
                            className="textfield"
                            size="small"
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <StyledTextField
                        label="Mapoteca"
                        className="textfield"
                        value={getValue("mapoteca")}
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Gaveta"
                        value={getValue("gaveta")}
                        size="small"
                        disabled
                        className="textfield"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Número da folha"
                        value={getValue("num_da_folha")}
                        size="small"
                        disabled
                        className="textfield"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Número de folha"
                        value={getValue("num_de_folha")}
                        size="small"
                        disabled
                        className="textfield"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Revisão"
                        value={getValue("revisao")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Área de projeto"
                        value={getValue("area_projeto")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Subárea de projeto"
                        value={getValue("sub_area_projeto")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Ano"
                        value={getValue("ano")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Tipo de planta"
                        value={getValue("tipo_planta")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Sistema Produtor de Água"
                        value={getValue("sistema")}
                        className="textfield"
                        size="small"
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </CardContent>
            </Card>
        </div>
    );
};
