import { createContext, useState } from "react";

export const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)

    function loadingTimeout(value){
        setLoading(true);
           
        setTimeout(() => {
            setLoading(false);
          }, value);
    }
    
    return (
        <LoadingContext.Provider
            value={{ loading, setLoading, loadingTimeout }}
        >
            {children}
        </LoadingContext.Provider>
    )
}