import { createContext, useState } from "react";

export const ProjectContext = createContext({});

export const ProjectProvider = ({ children }) => {
    const [selectedProject, setSelectedProject] = useState([]);
    
    const Projects = Object.freeze({
        AGUA: "b.c5a18a98-25e1-4601-a26c-9bba0c3f1386",
        ESGOTO: "b.21bbf623-c8c6-41cc-9e0c-16a5c8332b08",
        ARQUIVO: "b.9ab33768-a9c2-45ca-9457-1a9e3e0938a2",
        TECBIM: "b.1804abc4-b91b-4296-b200-f2945d8028b3",
        SIGNOS: "b.f72fd221-7c8b-44c7-9ff0-6e941fe3cfd3",
        NONE  :""
    });

    function changeSelectedProject(project) {
        setSelectedProject(project);
    }

    return (
        <ProjectContext.Provider
            value={{
                changeSelectedProject,
                selectedProject,
                Projects
            }}>
            {children}
        </ProjectContext.Provider>
    );
}