import { Button } from "@mui/material";

const buttonStyle = {
    textTransform: "none",
    fontWeight: 600,
    boxShadow: "none",
};

const FormButton = ({ text, onClick, variant, color, size, startIcon }) => {
    return (
        <Button
            style={buttonStyle}
            onClick={onClick}
            variant={variant}
            color={color}
            size={size}
            startIcon={startIcon}
        >
            {text}
        </Button>
    );
};

export default FormButton;
