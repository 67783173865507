import { Alert, Card, Snackbar, Tooltip, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ButtonPage from "./ButtonPage";
import WaterLight from "../../assets/images/home/water-light.png";
import WaterDark from "../../assets/images/home/water-dark.png";
import SewageLight from "../../assets/images/home/sewage-light.png";
import SewageDark from "../../assets/images/home/sewage-dark.png";
import TechnicalCollectionDark from "../../assets/images/home/technical_collection-dark.png";
import TechnicalCollectionLight from "../../assets/images/home/technical_collection-light.png";
import StandardizationDark from "../../assets/images/home/standardization-dark.png";
import StandardizationLight from "../../assets/images/home/standardization-light.png";
import BookletSignosDark from "../../assets/images/home/booklet_signos-dark.png";
import BookletSignosLight from "../../assets/images/home/booklet_signos-light.png";
import { useContext } from "react";
import { AlertContext } from "../../Provider/AlertProvider";
import {
    AecFolderContext,
    AecFolderProvider,
} from "../../Provider/AecFolderProvider";
import { ProjectContext } from "../../Provider/ProjectProvider";
import { AuthContext } from "../../Provider/AuthProvider";

const cardContainer = {
    borderRadius: "8px",
    padding: "24px",
    margin: "24px 48px",
};

const listPagesSectionStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    margin: "64px 16px 16px",
    gap: 20,
};

const ListPages = () => {
    const { Projects } = useContext(ProjectContext);
    const { projectsPermissions } = useContext(AecFolderContext);
    const { errorMessage, showErrorAlert, displayErrorAlert } =
        useContext(AlertContext);
    const { verifyToken } = useContext(AuthContext);

    let navigate = useNavigate();
    const theme = useTheme();

    const goToAgua = () => {
        verifyToken();

        if (!projectsPermissions.includes(Projects.AGUA)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            let path = `/agua`;
            navigate(path);
        }
    };

    const goToEsgoto = () => {
        verifyToken();

        if (!projectsPermissions.includes(Projects.ESGOTO)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            let path = `/esgoto`;
            navigate(path);
        }
    };

    const goToArquivoTecnico = () => {
        verifyToken();

        if (!projectsPermissions.includes(Projects.ARQUIVO)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            let path = `/arquivoTecnico`;
            navigate(path);
        }
    };

    const goToTecBIM = () => {
        verifyToken();

        if (!projectsPermissions.includes(Projects.TECBIM)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            displayErrorAlert("Em desenvolvimento");

            // let path = `/tecbim`;
            // navigate(path);
        }
    };

    const goToSignos = () => {
        verifyToken();

        if (!projectsPermissions.includes(Projects.SIGNOS)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            displayErrorAlert("Em desenvolvimento");
            // let path = `/signos`;
            // navigate(path);
        }
    };

    return (
        <main>
            <Card style={cardContainer}>
                <section style={listPagesSectionStyle}>
                    <ButtonPage
                        onClick={goToAgua}
                        image={
                            theme.palette.mode === "dark"
                                ? WaterLight
                                : WaterDark
                        }
                        text={"Água"}
                        color={"#4287f5"}
                        tooltip="Sistema Produtor de Água"
                    />
                    <ButtonPage
                        onClick={goToEsgoto}
                        image={
                            theme.palette.mode === "dark"
                                ? SewageLight
                                : SewageDark
                        }
                        text={"Esgoto"}
                        color={"#F4B942"}
                        tooltip="Sistema de Esgotamento Sanitário"
                    />
                    <ButtonPage
                        onClick={goToArquivoTecnico}
                        image={
                            theme.palette.mode === "dark"
                                ? TechnicalCollectionLight
                                : TechnicalCollectionDark
                        }
                        text={"Arquivo Técnico"}
                        tooltip={"SAT"}
                        color={"#06d6a0"}
                    />
                    <ButtonPage
                        onClick={goToTecBIM}
                        image={
                            theme.palette.mode === "dark"
                                ? StandardizationLight
                                : StandardizationDark
                        }
                        text={"TecBIM"}
                        color={"#b5a2cd"}
                    />
                    <ButtonPage
                        onClick={goToSignos}
                        image={
                            theme.palette.mode === "dark"
                                ? BookletSignosLight
                                : BookletSignosDark
                        }
                        text={"Caderneta Signos"}
                        color={"#f45b69"}
                    />
                </section>
            </Card>
            <Snackbar
                open={showErrorAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </main>
    );
};

export default ListPages;
