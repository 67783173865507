import React from 'react';
import styles from './index.module.css';

import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Generic file input component.
 * @param {string} placeholder - The placeholder text for the file input button.
 * @param {string} [accept] - The file types accepted by the input (e.g., "image/*", ".pdf").
 * @param {boolean} [disabled=false] - Whether the input button is disabled or not.
 * @param {string} [id] - The id attribute of the input button.
 * @param {function} onFileSelect - The function to be called when a file is selected.
 * @param {React.MutableRefObject<null>} inputRef - Ref object for the file input.
 */
const Input = ({placeholder, accept, disabled, id, onFileSelect, inputRef}) => {
  const fileInputRef = inputRef;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) onFileSelect(file);
  };

  const handleClearSelection = () => {
    onFileSelect(null);
    if (fileInputRef.current) fileInputRef.current.value = null;
    disabled=false;
  };

  return (<div className={styles.inputDiv}>
    <Button variant="outlined" component="label" startIcon={<UploadIcon />} disabled={disabled} id={id}>
      {placeholder}
      <input
        ref={fileInputRef}
        style={{display: "none"}}
        type="file"
        accept={accept}
        onChange={handleFileChange}
      />
    </Button>
    { disabled ? <CloseIcon color="primary" className={styles.closeBtn} onClick={handleClearSelection}/> : null }
  </div>);
};

export default Input;