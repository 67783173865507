import React from "react";
import { useContext } from "react";
import { ProjectContext } from "../../Provider/ProjectProvider";
import FormButtonMenu from "./ButtonMenu";
import WaterLight from "../../assets/images/home/water-light.png";
import WaterDark from "../../assets/images/home/water-dark.png";
import SewageLight from "../../assets/images/home/sewage-light.png";
import SewageDark from "../../assets/images/home/sewage-dark.png";
import TechnicalCollectionDark from "../../assets/images/home/technical_collection-dark.png";
import TechnicalCollectionLight from "../../assets/images/home/technical_collection-light.png";
import StandardizationDark from "../../assets/images/home/standardization-dark.png";
import StandardizationLight from "../../assets/images/home/standardization-light.png";
import BookletSignosDark from "../../assets/images/home/booklet_signos-dark.png";
import BookletSignosLight from "../../assets/images/home/booklet_signos-light.png";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../Provider/AlertProvider";
import { Alert, Snackbar, useTheme } from "@mui/material";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import { AuthContext } from "../../Provider/AuthProvider";

const formSidebarStyle = {
    position: "fixed",
    left: "-30px",
    top: 0,
    height: "100vh",
    width: 100,
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    gap: 10,
};

const FormSidebar = () => {
    const { Projects, selectedProject } = useContext(ProjectContext);
    const { clearFolderContents, projectsPermissions } = useContext(AecFolderContext);
    const { errorMessage, showErrorAlert, displayErrorAlert } = useContext(AlertContext);
    const { verifyToken } = useContext(AuthContext);

    let navigate = useNavigate();
    const theme = useTheme();

    const goToAgua = () => {
        verifyToken()
        if (!projectsPermissions.includes(Projects.AGUA)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            let path = `/agua`;
            navigate(path);
            clearFolderContents();
        }
    };

    const goToEsgoto = () => {
        verifyToken()

        if (!projectsPermissions.includes(Projects.ESGOTO)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            let path = `/esgoto`;
            navigate(path);
            clearFolderContents();
        }
    };

    const goToArquivoTecnico = () => {
        verifyToken()

        if (!projectsPermissions.includes(Projects.ARQUIVO)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            let path = `/arquivoTecnico`;
            navigate(path);
        }
    };

    const goToTecBIM = () => {
        verifyToken()

        if (!projectsPermissions.includes(Projects.TECBIM)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            displayErrorAlert("Em desenvolvimento");

            // let path = `/tecbim`;
            // navigate(path);
        }
    };

    const goToSignos = () => {
        verifyToken()

        if (!projectsPermissions.includes(Projects.SIGNOS)) {
            displayErrorAlert(
                "Você não possui permissão para acessar o projeto selecionado."
            );
        } else {
            displayErrorAlert("Em desenvolvimento");
            // let path = `/signos`;
            // navigate(path);
        }
    };

    return (
        <div style={formSidebarStyle}>
            <FormButtonMenu
                isSelected={selectedProject === Projects.AGUA}
                onClick={goToAgua}
                text={"Água"}
                color={"#4287f5"}
                image={
                    theme.palette.mode === "dark"
                        ? WaterLight
                        : WaterDark
                }
                // width={100}
                // padding="2rem 0 0.75rem"
            />
            <FormButtonMenu
                isSelected={selectedProject === Projects.ESGOTO}
                onClick={goToEsgoto}
                text={"Esgoto"}
                color={"#F4B942"}
                image={
                    theme.palette.mode === "dark"
                        ? SewageLight
                        : SewageDark
                }
                // width={120}
                // padding="0.75rem 0"
            />
            <FormButtonMenu
                isSelected={selectedProject === Projects.ARQUIVO}
                onClick={goToArquivoTecnico}
                text={"SAT"}
                color={"#06d6a0"}
                image={
                    theme.palette.mode === "dark"
                        ? TechnicalCollectionLight
                        : TechnicalCollectionDark
                }
                // width={120}
                // padding="0.75rem 0"
            />
            <FormButtonMenu
                isSelected={selectedProject === Projects.TECBIM}
                onClick={goToTecBIM}
                text={"TecBIM"}
                color={"#b5a2cd"}
                image={
                    theme.palette.mode === "dark"
                        ? StandardizationLight
                        : StandardizationDark
                }
                // width={150}
            />
            <FormButtonMenu
                isSelected={selectedProject === Projects.SIGNOS}
                onClick={goToSignos}
                text={"Signos"}
                color={"#f45b69"}
                image={
                    theme.palette.mode === "dark"
                        ? BookletSignosLight
                        : BookletSignosDark
                }
                // width={100}
                // padding="2rem 0 0.75rem"
            />
            <Snackbar
                open={showErrorAlert}
                sx={{ height: "100%" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </div>
    );
};

export default FormSidebar;
