import { useContext, useRef } from "react";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import DocumentViewer from "../DocumentViewer";
import FileList from "../FileList";
import "./ItemSearch.css";
import { ProjectContext } from "../../Provider/ProjectProvider";
import { MetadataEsgoto } from "../Metadata/metadataEsgoto";
import { MetadataAgua } from "../Metadata/metadataAgua";
import MetadataOSE from "../Metadata/ArquivoTecnico/metadataOSE.js";
import MetadataPEC from "../Metadata/ArquivoTecnico/metadataPEC.js";


const ItemSearch = () => {
    const metadataContainerRef = useRef(null);

    const { selectedFile, selectedTab } = useContext(AecFolderContext);
    const { Projects, selectedProject } = useContext(ProjectContext);


    var Components = {
        MetadataAgua,
        MetadataEsgoto,
        MetadataPEC,
        MetadataOSE
    };

    var Component;

    if (selectedProject === Projects.AGUA) {
        Component = Components.MetadataAgua;
    } else if (selectedProject === Projects.ESGOTO) {
        Component = Components.MetadataEsgoto;
    } else if (selectedProject === Projects.ARQUIVO) {
        if (selectedTab === 0) {
            Component = Components.MetadataPEC;
        }
        else  {
            Component = Components.MetadataOSE;
        }
       
       
    }
    
    return (
        <>
            <div className="search-container">
                <FileList />
                {selectedFile && selectedFile.id ? (
                    <div>
                        <DocumentViewer
                            metadataContainerRef={metadataContainerRef}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div>
                {selectedFile && selectedFile.id ? (
                    <Component metadataContainerRef={metadataContainerRef} />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default ItemSearch;
