/**
 * Creates an object for managing request cancellation for each API endpoint.
 * @param {object} apiObject - The API object containing various endpoint functions.
 */
export function defineCancelApiObject(apiObject) {
    const cancelApiObject = {}

    Object.getOwnPropertyNames(apiObject).forEach((apiPropertyName) => {
        const cancellationControllerObject = {
            controller: undefined,
        }

        cancelApiObject[apiPropertyName] = {
            handleRequestCancellation: () => {
                if (cancellationControllerObject.controller) {
                    cancellationControllerObject.controller.abort()
                }
                cancellationControllerObject.controller = new AbortController()

                return cancellationControllerObject.controller
            },
        }
    })

    return cancelApiObject
}