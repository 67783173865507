import { Card } from "@mui/material";
import { useContext, useEffect } from "react";
import ItemSearch  from "../../components/ItemSearch";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import Header from "../../components/Header";
import Footer from "../../containers/footer";
import { ProjectContext } from "../../Provider/ProjectProvider";
import FormAgua from "../../components/Form/Agua";
import FormSidebar from "../../components/Form/Sidebar";

const cardContainer = {
    borderRadius: "8px",
    padding: "24px",
    margin: "24px 48px",
    display: "grid"
};

const Agua = () => {
    const { folderContents } =  useContext(AecFolderContext);
    const { changeSelectedProject, Projects } = useContext(ProjectContext);

    useEffect(() => {
        changeSelectedProject(Projects.AGUA);
    });

    return (
        <>
            <Header />
            <main>
                <Card style={cardContainer}>
                    <FormSidebar />
                    <FormAgua />
                    {folderContents.length ? <ItemSearch /> : <></>}           
                </Card>
            </main>
            <Footer />
        </>
    );
};

export default Agua;
