import { useTheme } from "@mui/material";
import bimSabespLogoLight from "../../assets/bim_sabesp_logo-ligth.png";
import bimSabespLogoDark from "../../assets/bim_sabesp_logo-dark.png";

const bimSabespLogoStyle = {
    width: "170px",
    height: "67px",
};

export const BimSabespLogo = () => {
    const theme = useTheme();

    return (
        <>
            <img
                style={bimSabespLogoStyle}
                key={theme.palette.mode}
                src={
                    theme.palette.mode === "dark"
                        ? bimSabespLogoLight
                        : bimSabespLogoDark
                }
                alt="BIM Sabesp Logo"
            />
        </>
    );
};
