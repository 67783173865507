import axios from "axios";
import { redirectToAuth } from "./AuthService";

const apiRequest = axios;

apiRequest.interceptors.response.use(function (response) {

    return response;
}, function (error) {
    if (error.response.status === '401')
        redirectToAuth();

    return Promise.reject(error);
});

export default apiRequest;