import { Card, CardContent, Divider, List, Button, FormControlLabel, Checkbox } from "@mui/material";
import { useContext, useState } from "react";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import FileCard  from "../FileCard";
import "./FileList.css"
import { AuthContext } from "../../Provider/AuthProvider";

const cardHeaderStyle = {
    display: "flex",
    gap: 16,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 24,
};


const buttonStyle = {
    textTransform: "none",
    width: "100%",
    margin: "24px 0",
};

const FileList = () => {
    const [checked, setChecked] = useState(false);
    const { setSelectedFile, setSelectedFolders, folderContents, downloadAllFiles } = useContext(AecFolderContext);

    const handleChange = (event) => {
        setSelectedFile({});
        setSelectedFolders([]);
        setChecked(event.target.checked);
    };
    const { verifyToken} = useContext(AuthContext);

    return (
        <div className="file-list-container">
            <Card className="file-card-container">
                <div style={cardHeaderStyle}>
                    <CreateNewFolderIcon className="icon" />
                    <label className="title-header">Resultado</label>
                    <div className="result-count">{folderContents.length}</div>
                </div>
                <Divider />
                <Button
                    onClick={() => {
                        verifyToken();
                        downloadAllFiles(checked);
                    }}
                    variant="outlined"
                    color="primary"
                    style={buttonStyle}
                    startIcon={<CloudDownloadOutlinedIcon />}
                >
                   {checked ? "Baixar selecionados" : "Baixar todos"}
                </Button>
                <FormControlLabel className="checkbox-center" control={<Checkbox checked={checked} onChange={handleChange} />} label="Selecionar múltiplos arquivos" />
                <Divider />
                <CardContent style={{ padding: 0 }}>
                    <List>
                        { 
                       
                        folderContents.length ? (
                            folderContents.map((fc, index) => { 
                                return (
                                    <FileCard file={fc} key={fc.id + index} selectedMultiples={checked} />
                                );
                            })
                        ) : (
                            <div>Nenhum arquivo encontrado</div>
                        )}
                    </List>
                </CardContent>
            </Card>
        </div>
    );
};

export default FileList;
