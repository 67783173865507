import axios from 'axios'

/**
 * Configuration of the Axios instance for communicating with the migration API.
 */
export const api = axios.create({
  baseURL: process.env.REACT_APP_MIGRATION_BACKEND_URL,
})

/**
 * Function to handle API response errors.
 * Logs the error to the console if the response status is not 401.
 * @param {Error} error - The error object caught by the Axios request.
 */
const errorHandler = (error) => {
  const statusCode = error.response?.status
  if (statusCode && statusCode !== 401) console.error(error)

  return Promise.reject(error)
}

api.interceptors.response.use(undefined, (error) => { // Intercept all API error responses using the defined errorHandler
  return errorHandler(error)
})